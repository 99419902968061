<template>
	<div class="container background-white w-100 mb-50 py-60 px-50 shadow-lg rounded">
		<header>
			<div class="container">
				<img src="@/assets/terminal.svg" alt="" />
			</div>
		</header>

		<div class="flex justify-center" v-if="loading">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<main class="container" v-if="!loading">
			<h1 class="mb-10">Vátta ognarskap og kontu</h1>

			<p class="mb-20">
				Kunnu tit vinaliga vátta, at <span class="heavy">{{ customer.name }}</span> eigur kontu
				<span class="heavy">{{ account.registrationNumber }} {{ account.accountNumber }}</span> við at skriva undir og stempla bankaváttanina, tú fært
				herundir, fyri síðan at leggja bankaváttanina uppaftur til okkum.
			</p>

			<div v-if="!success" class="flex justify-center">
				<div class="btn small btn-white border mr-10" @click="getFile"><i class="fas fa-download mr-5"></i> Tak fílu niður</div>

				<div class="btn small" @click="$refs.fileInput.click()"><i class="fas fa-upload mr-5"></i> Legg upp aftur</div>
			</div>

			<div v-if="success" class="text-center color-green">
				<i class="fas fa-check mr-5"></i>

				Fílan er móttikin. Takk fyri!
			</div>

			<a ref="anchor" href="#" download></a>
			<input ref="fileInput" type="file" @change="uploadVerificationFile" class="display-none" accept="application/pdf" />

			<div v-if="availableDocuments.length">
				<hr class="my-30" />

				<h2 class="mb-10">Skjøl</h2>

				<p class="mb-30">
					Niðanfyri síggjast skjøl ið kunnu hava bankans áhuga ísamband við bankaváttan. Tú hevur einans atgongd til skjøl sum kundin longu hevur
					vátta.
				</p>

				<a
					v-for="(document, index) in availableDocuments"
					:key="`document-${index}`"
					href="#"
					@click.prevent="downloadDocument(document)"
					class="document flex space-between block py-30 px-20 color-blue background-white border-light rounded-lg hover:background-ultra-light-grey-300"
					:class="{ disabled: !document.file }"
				>
					<div class="heavy color-black">{{ document.name }}</div>

					<div v-if="document.file">Heinta</div>

					<div v-if="!document.file">Ikki tøkt enn</div>
				</a>

				<a ref="anchor" href="#" download class="display-none"></a>
			</div>
		</main>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Bank',

	components: {},

	data() {
		return {
			name: '',

			loading: true,
			success: false,
			error: false,

			account: null,
			customer: null,
			agreement: null,

			documents: [],
		};
	},

	mounted() {
		const token = this.$route.query.token;
		const agreementUuid = this.$route.query['agreement-uuid'];

		axios
			.get(`/bank-accounts/bank-verification/${token}/${agreementUuid}`)
			.then((response) => {
				this.account = response.data.bankAccount;
				this.customer = this.account.customer;

				this.agreement = response.data.agreement;

				let originalFiles = [];

				originalFiles = [...this.agreement.originalFiles.map((originalFile) => ({ ...originalFile, file: null }))];

				for (const file of this.agreement.files) {
					const originalFile = originalFiles.find((originalFile) => originalFile.id == file.originalFile?.id);

					if (originalFile) {
						originalFile.file = file;
					}
				}

				this.documents = originalFiles;
			})
			.catch((error) => {
				console.log('error', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	computed: {
		availableDocuments() {
			if (!this.documents) {
				return [];
			}

			return this.documents.filter((document) => document.file);
		},
	},

	methods: {
		getFile() {
			const token = this.$route.query.token;

			axios
				.get(`/bank-accounts/bank-verification/${token}/get-file`, {
					responseType: 'blob',
				})
				.then((response) => {
					const url = URL.createObjectURL(response.data);

					this.$refs.anchor.download = 'ownership.pdf';
					this.$refs.anchor.href = url;

					this.$nextTick(() => {
						this.$refs.anchor.click();
					});
				})
				.catch((error) => {
					console.log('error', error);
				});
		},

		uploadVerificationFile(e) {
			const formData = new FormData();
			const agreementUuid = this.$route.query['agreement-uuid'];

			// change the filename to 'bankaváttan_01-01-2001'
			const file = e.target.files[0];
			const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			const fileName = `bankaváttan_${new Date().format('%d-%m-%y')}.${extension}`;

			formData.append('file', new File([file], fileName, { type: file.type }));
			formData.append('agreement_uuid', agreementUuid);

			this.loading = true;

			axios({
				method: 'post',
				url: `/bank-accounts/bank-verification/${this.account.uuid}`,
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then((response) => {
					this.loading = false;

					this.success = true;
				})
				.catch(() => {
					this.error = true;
				});
		},

		downloadDocument(document) {
			const token = this.$route.query.token;
			const agreementUuid = this.$route.query['agreement-uuid'];

			axios
				.get(`/bank-accounts/bank-verification/${token}/${agreementUuid}/${document.file.uuid}`, {
					responseType: 'blob',
				})
				.then((response) => {
					const url = URL.createObjectURL(response.data);

					this.$refs.anchor.download = document.file.name;
					this.$refs.anchor.href = url;

					this.$nextTick(() => {
						this.$refs.anchor.click();
					});
				})
				.catch((error) => console.log(error));
		},
	},
};
</script>

<style lang="scss" scoped>
.container {
	max-width: 720px;

	p {
		line-height: 1.6;

		color: var(--color-grey-900);
	}
}

.document {
	margin-bottom: 16px;

	&:last-of-type {
		margin-bottom: 0;
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}
</style>
